import { nl } from 'date-fns/locale'

import { AppConfigInterface } from '@app-config/app/app-config.interface'

// noinspection JSUnusedGlobalSymbols
export const AppConfig: AppConfigInterface = {

    environment: 'staging',

    locales: [
        nl,
    ],

    api: {
        graphQLEndpoint: 'https://api.papageno.kubernetes.pwstaging.tech/graphql',
    },

    cache: {
        cacheByDefault: true,
        ttl: 60 * 60,
    },

    bugsnag: {
        enabled: false,
    },

    streamChat: {
        appId: '1262906',
        apiKey: '8mfv7hv8zwy6',
    },

}
