import { registerLocaleData } from '@angular/common'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import localeNl from '@angular/common/locales/nl'
import { ErrorHandler, isDevMode, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouteReuseStrategy } from '@angular/router'
import { ServiceWorkerModule } from '@angular/service-worker'
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { IonicStorageModule } from '@ionic/storage-angular'
import { IModuleTranslationOptions, ModuleTranslateLoader } from '@larscom/ngx-translate-module-loader'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'

import lottiePlayer from 'lottie-web'
import { LottieModule } from 'ngx-lottie'

import { AppConfig } from '@app-config'
import { GraphQLModule } from '@app-modules/graphql.module'
import { NativeServiceModule } from '@app-services/native/native-service.module'
import { NavigationServiceModule } from '@app-services/navigation/navigation.service.module'
import { StorageServiceModule } from '@app-services/storage/storage-service.module'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import packageJson from '@project-root/package.json'

/**
 * i18n
 */
export function HttpLoaderFactory(http: HttpClient) {
    const baseTranslateUrl = './assets/i18n'

    const options: IModuleTranslationOptions = {
        disableNamespace: true,
        modules: [
            { baseTranslateUrl },
            { baseTranslateUrl, moduleName: 'streamChat' },
        ],
    }

    return new ModuleTranslateLoader(http, options)
}

registerLocaleData(localeNl, 'nl')

/**
 * Lottie animations
 */
export function lottiePlayerFactory() {
    return lottiePlayer
}

/**
 * Bugsnag
 */
const errorHandlerProviders = []

if (AppConfig.bugsnag?.enabled) {
    function errorHandlerFactory() {
        return new BugsnagErrorHandler()
    }

    Bugsnag.start({
        apiKey: AppConfig.bugsnag.apiKey,
        appVersion: (AppConfig.environment === 'production') ? packageJson.version : 'staging',
        appType: (window as any).Capacitor?.isNativePlatform() ? 'app' : 'browser',
        redactedKeys: [
            'token',
            'password',
            'currentPassword',
        ],
        onError: (event) => ! event.originalError.message?.includes('ApolloError'),
    })

    errorHandlerProviders.push({
        provide: ErrorHandler,
        useFactory: errorHandlerFactory,
    })
}

/**
 * App module
 */
@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        GraphQLModule,
        HttpClientModule,
        IonicModule.forRoot({
            mode: (/(iphone|ipad|ipod|macintosh|mac os)/i).test(navigator.userAgent) ? 'ios' : 'md',
            animated: ! window.matchMedia('(prefers-reduced-motion: reduce)')?.matches,
            swipeBackEnabled: (/cordova|capacitor|ionic/i).test(navigator.userAgent),
        }),
        IonicStorageModule.forRoot({
            name: 'dito',
        }),
        LottieModule.forRoot({ player: lottiePlayerFactory }),
        NavigationServiceModule,
        NativeServiceModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: ! isDevMode(),
            registrationStrategy: 'registerWhenStable:30000',
        }),
        StorageServiceModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    providers: [
        ...errorHandlerProviders,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
        HttpClientModule,
    ],
    bootstrap: [
        AppComponent,
    ],
})
export class AppModule {
}
