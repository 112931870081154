import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'

import { AuthGuard } from '@app-guards'

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () => import('@app-pages/tabs/tabs.page.module').then((m) => m.TabsPageModule),
    },
    {
        path: 'auth',
        loadChildren: () => import('@app-pages/auth/auth-page.module').then((m) => m.AuthPageModule),
    },
    {
        path: 'chat',
        canActivate: [AuthGuard],
        loadChildren: () => import('@app-pages/chat/chat-page.module').then((m) => m.ChatPageModule),
    },
    {
        path: 'community',
        canActivate: [AuthGuard],
        loadChildren: () => import('@app-pages/community/community-page.module').then((m) => m.CommunityPageModule),
    },
    {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () => import('@app-pages/profile/profile-page.module').then((m) => m.ProfilePageModule),
    },
    {
        path: 'ui-kit',
        loadChildren: () => import('@app-pages/ui-kit/ui-kit-page.module').then((m) => m.UiKitPageModule),
    },
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
