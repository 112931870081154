import { Component, OnInit } from '@angular/core'
import { SplashScreen } from '@capacitor/splash-screen'
import { Platform } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'

import {
    AutocapitalizeFixService,
    BackButtonService,
    KeyboardHelperService,
    StatusBarStyleService,
} from '@app-services'

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

    public settingsMenuVisible: boolean

    constructor(
        private readonly autocapitalizeFixService: AutocapitalizeFixService,
        private readonly backButtonService: BackButtonService,
        private readonly keyboardHelperService: KeyboardHelperService,
        private readonly platform: Platform,
        private readonly statusBarStyleService: StatusBarStyleService,
        private readonly translateService: TranslateService,
    ) {
        //
    }

    public async ngOnInit(): Promise<void> {
        await this.platform.ready()
        await Promise.all([
            this.initialize(),
            this.setLocale(),
        ])

        this.settingsMenuVisible = this.platform.is('hybrid')
    }

    protected async initialize(): Promise<void> {
        this.backButtonService.initialize()
        if (this.platform.is('hybrid')) {
            this.autocapitalizeFixService.initialize()
            this.keyboardHelperService.initialize()
            await SplashScreen.hide()
            await this.statusBarStyleService.initialize()
        }
    }

    protected async setLocale(): Promise<void> {
        // @TODO: Uncomment when app becomes multi-lingual
        // const savedLocaleCode = await this.storageService.get('locale');
        // const defaultLocaleCode = this.translateService.getBrowserLang();
        //
        // const localeCode = savedLocaleCode || defaultLocaleCode;
        // const locale = AppConfig.locales.find((l) => l.code === localeCode) || AppConfig.locales[0];
        //
        // this.translateService.use(locale.code);
        this.translateService.use('nl')
    }

}
