import { MutationResult } from 'apollo-angular'
import { Injectable } from '@angular/core'
import { NavController } from '@ionic/angular'
import { firstValueFrom, Subject } from 'rxjs'

import {
    ForgotPasswordMutation,
    ForgotPasswordMutationService,
    LoginInput,
    LoginMutation,
    LoginMutationService,
    LogoutMutation,
    LogoutMutationService,
    RegisterInput,
    RegisterMutation,
    RegisterMutationService,
    ResendEmailVerificationInput,
    ResendEmailVerificationMutation,
    ResendEmailVerificationMutationService,
    ResetPasswordInput,
    ResetPasswordMutation,
    ResetPasswordMutationService,
    VerifyEmailInput,
    VerifyEmailMutation,
    VerifyEmailMutationService,
} from '@app-graphql'
import { ApiHelperService } from '@app-services/api/api-helper.service'
import { StorageService } from '@app-services/storage'
import { ChatService } from '@app-services/chat'

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    public authenticationInProgress = false
    public userWasRedirectedAsAlreadyLoggedIn = false

    public loginStateChanged$ = new Subject<boolean>()

    private token: string

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly chatService: ChatService,
        private readonly forgotPasswordMutationService: ForgotPasswordMutationService,
        private readonly loginMutationService: LoginMutationService,
        private readonly logoutMutationService: LogoutMutationService,
        private readonly navController: NavController,
        private readonly registerMutationService: RegisterMutationService,
        private readonly resendEmailVerificationMutationService: ResendEmailVerificationMutationService,
        private readonly resetPasswordMutationService: ResetPasswordMutationService,
        private readonly storageService: StorageService,
        private readonly verifyEmailMutationService: VerifyEmailMutationService,
    ) {
    }

    public async initialize(): Promise<void> {
        if (this.authenticationInProgress) {
            return
        }

        this.authenticationInProgress = true

        this.token = await this.storageService.get('token')
        if (! this.token) {
            await this.redirectToLoginIfNeeded()
        }

        this.authenticationInProgress = false
    }

    public async isAuthenticated(): Promise<boolean> {
        await this.initialize()
        return !! this.token
    }

    public getToken(): string {
        return this.token
    }

    public async login(input: LoginInput): Promise<LoginMutation> {
        try {
            const response = await firstValueFrom(
                this.loginMutationService.mutate({ input }),
            )

            this.token = response.data?.login?.token
            if (this.token) {
                await this.storageService.set('token', this.token)
                this.loginStateChanged$.next(true)
            }

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async forgotPassword(email: string): Promise<ForgotPasswordMutation> {
        try {
            const response = await firstValueFrom(
                this.forgotPasswordMutationService.mutate({ input: { email } }),
            )
            return response.data
        } catch (e) {
            await this.logout(true)
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async resetPassword(input: ResetPasswordInput): Promise<ResetPasswordMutation> {
        try {
            const response = await firstValueFrom(
                this.resetPasswordMutationService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async logout(redirectToLogin = false): Promise<LogoutMutation> {
        let response: MutationResult<LogoutMutation> | undefined

        if (this.getToken()) {
            try {
                response = await firstValueFrom(this.logoutMutationService.mutate())
            } catch (e) {
            }
        }

        this.token = null

        await Promise.all([
            this.chatService.disconnect(),
            this.storageService.remove('token'),
            this.apiHelperService.invalidateCache(),
        ])

        this.userWasRedirectedAsAlreadyLoggedIn = false
        this.loginStateChanged$.next(false)

        if (redirectToLogin) {
            await this.redirectToLoginIfNeeded()
        }

        return response?.data
    }

    public async register(input: RegisterInput): Promise<RegisterMutation> {
        try {
            const response = await firstValueFrom(
                this.registerMutationService.mutate({ input }),
            )

            this.token = response.data?.register?.token
            if (this.token) {
                await this.storageService.set('token', this.token)
            }

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async verifyEmail(input: VerifyEmailInput): Promise<VerifyEmailMutation> {
        try {
            const response = await firstValueFrom(
                this.verifyEmailMutationService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async resendEmailVerification(
        input: ResendEmailVerificationInput,
    ): Promise<ResendEmailVerificationMutation> {
        try {
            const response = await firstValueFrom(
                this.resendEmailVerificationMutationService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    private async redirectToLoginIfNeeded(): Promise<void> {
        if (! location.pathname.startsWith('/auth')) {
            await this.navController.navigateBack('/auth', { replaceUrl: true })
        }
    }

}
