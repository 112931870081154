import { Injectable } from '@angular/core'
import { Capacitor } from '@capacitor/core'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { AlertController, Platform } from '@ionic/angular'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { TranslateService } from '@ngx-translate/core'
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings'

import { LocalFile } from '@app-interfaces'

@Injectable({
    providedIn: 'root',
})
export class FileHelperService {

    constructor(
        private readonly alertController: AlertController,
        private readonly platform: Platform,
        private readonly translateService: TranslateService,
    ) {
    }

    public async browsePhoto(): Promise<LocalFile> {
        const localFile: LocalFile = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            source: CameraSource.Photos,
            resultType: CameraResultType.DataUrl,
            saveToGallery: true,
            width: 2048,
            height: 2048,
            presentationStyle: 'popover',
        })

        if (localFile?.dataUrl && ! localFile?.webPath) {
            localFile.webPath = localFile.dataUrl
        }

        return localFile
    }

    public async takePhoto(): Promise<LocalFile> {
        if (this.platform.is('hybrid')) {
            const { camera } = await Camera.requestPermissions()

            if (camera === 'denied') {
                await this.showCameraPermissionMessage()
                return null
            }
        }

        const localFile: LocalFile = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            source: CameraSource.Camera,
            resultType: CameraResultType.DataUrl,
            saveToGallery: true,
            width: 2048,
            height: 2048,
            presentationStyle: 'popover',
        })

        if (localFile?.dataUrl && ! localFile?.webPath) {
            localFile.webPath = localFile.dataUrl
        }

        return localFile
    }

    public dataUrlToBlob(dataUrl: string): Blob {
        const byteString = atob(dataUrl?.split(',')?.[1] || '')
        const mimeType = dataUrl?.split(',')?.[0]?.split(':')?.[1]?.split(';')?.[0]
        const buffer = new ArrayBuffer(byteString.length)

        let ia = new Uint8Array(buffer)
        ia.set(new Uint8Array(byteString.length).map((_, i) => byteString.charCodeAt(i)))

        return new Blob([buffer], { type: mimeType })
    }

    public blobToDataUrl(blob: Blob): Promise<string> {
        let reader: FileReader = new FileReader()

        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                resolve(reader.result as string)
            }
            reader.onerror = () => {
                reject('Error reading file.')
            }
            reader.readAsDataURL(blob)
        })
    }

    public async writeBlobToFile(blob: Blob, path: string): Promise<string> {
        const dataUri = await this.blobToDataUrl(blob)
        const data = dataUri.split(',')[1]

        const file = await Filesystem.writeFile({
            data,
            path,
            directory: Directory.Data,
            recursive: true,
        })

        return Capacitor.convertFileSrc(file.uri)
    }

    public async openCameraPermissionSettings(): Promise<void> {
        await NativeSettings.open({
            optionAndroid: AndroidSettings.ApplicationDetails,
            optionIOS: IOSSettings.App,
        })
    }

    private async showCameraPermissionMessage(): Promise<void> {
        const alert = await this.alertController.create({
            header: this.translateService.instant('camera.permissionRequired.title'),
            message: this.translateService.instant('camera.permissionRequired.message'),
            buttons: [
                {
                    text: this.translateService.instant('common.cancel'),
                    role: 'cancel',
                },
                {
                    text: this.translateService.instant('camera.permissionRequired.allow'),
                    handler: async () => {

                        // Open camera settings if camera is turned off or permission was explicitly denied
                        const { camera } = await Camera.requestPermissions()
                        if (camera === 'denied') {
                            await this.openCameraPermissionSettings()
                            return
                        }

                        // Otherwise, request the camera permission within the app
                        await Camera.requestPermissions()
                    },
                },
            ],
        })
        await alert.present()
    }

}
