// noinspection JSUnusedGlobalSymbols

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: { input: string; output: string; }
    String: { input: string; output: string; }
    Boolean: { input: boolean; output: boolean; }
    Int: { input: number; output: number; }
    Float: { input: number; output: number; }
    /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
    Date: { input: any; output: any; }
    /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
    DateTime: { input: any; output: any; }
    /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
    Upload: { input: any; output: any; }
}

export interface AccessToken {
    token: Scalars['String']['output'];
}

export interface Attachment {
    file: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    owner: Owner;
}

export type Author = OrganizationAuthor | Profile;

export interface ChatParticipant {
    id: Scalars['ID']['output'];
    token?: Maybe<Scalars['String']['output']>;
}

export interface Community {
    description: Scalars['String']['output'];
    icon: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    isJoinedByMe?: Maybe<Scalars['Boolean']['output']>;
    name: Scalars['String']['output'];
    profileCommunities: Array<ProfileCommunity>;
}

export interface CreatePostInput {
    attachments?: InputMaybe<Array<Scalars['Upload']['input']>>;
    message: Scalars['String']['input'];
    publicationChannelId: Scalars['ID']['input'];
}

export interface CreateProfileInput {
    avatar: Scalars['Upload']['input'];
    birthdate: Scalars['Date']['input'];
    communities: Array<Scalars['ID']['input']>;
    description: Scalars['String']['input'];
    name: Scalars['String']['input'];
}

export interface EmailVerificationResponse {
    status: EmailVerificationStatus;
    token: Scalars['String']['output'];
}

export enum EmailVerificationStatus {
    /** VERIFIED */
    Verified = 'VERIFIED',
}

export interface ForgotPasswordInput {
    email: Scalars['String']['input'];
}

export interface ForgotPasswordResponse {
    message?: Maybe<Scalars['String']['output']>;
    status: ForgotPasswordStatus;
}

export enum ForgotPasswordStatus {
    /** EMAIL_SENT */
    EmailSent = 'EMAIL_SENT',
}

export interface LoginInput {
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
}

export interface LogoutResponse {
    message: Scalars['String']['output'];
    status: LogoutStatus;
}

export enum LogoutStatus {
    /** TOKEN_REVOKED */
    TokenRevoked = 'TOKEN_REVOKED',
}

export interface Mutation {
    createPost: Post;
    createProfile: Profile;
    deleteMe: Scalars['Boolean']['output'];
    deletePost: Scalars['Boolean']['output'];
    followProfile: Profile;
    forgotPassword: ForgotPasswordResponse;
    joinCommunity: ProfileCommunity;
    leaveCommunity: ProfileCommunity;
    likePost: Post;
    login: AccessToken;
    logout: LogoutResponse;
    pauseProfile: Profile;
    register: RegisterResponse;
    reportPost: Post;
    reportProfile: Profile;
    resendEmailVerification: ResendEmailVerificationResponse;
    resetPassword: ResetPasswordResponse;
    resumeProfile: Profile;
    sharePost: Post;
    unfollowProfile: Profile;
    unlikePost: Post;
    updateMe: User;
    updatePassword: UpdatePasswordResponse;
    updateProfile: Profile;
    verifyEmail: EmailVerificationResponse;
}


export interface MutationCreatePostArgs {
    input: CreatePostInput;
}


export interface MutationCreateProfileArgs {
    input: CreateProfileInput;
}


export interface MutationDeletePostArgs {
    id: Scalars['ID']['input'];
}


export interface MutationFollowProfileArgs {
    id: Scalars['ID']['input'];
}


export interface MutationForgotPasswordArgs {
    input: ForgotPasswordInput;
}


export interface MutationJoinCommunityArgs {
    id: Scalars['ID']['input'];
}


export interface MutationLeaveCommunityArgs {
    id: Scalars['ID']['input'];
}


export interface MutationLikePostArgs {
    id: Scalars['ID']['input'];
}


export interface MutationLoginArgs {
    input?: InputMaybe<LoginInput>;
}


export interface MutationRegisterArgs {
    input?: InputMaybe<RegisterInput>;
}


export interface MutationReportPostArgs {
    input: ReportPostInput;
}


export interface MutationReportProfileArgs {
    input: ReportProfileInput;
}


export interface MutationResendEmailVerificationArgs {
    input: ResendEmailVerificationInput;
}


export interface MutationResetPasswordArgs {
    input: ResetPasswordInput;
}


export interface MutationSharePostArgs {
    input: SharePostInput;
}


export interface MutationUnfollowProfileArgs {
    id: Scalars['ID']['input'];
}


export interface MutationUnlikePostArgs {
    id: Scalars['ID']['input'];
}


export interface MutationUpdateMeArgs {
    input: UpdateMeInput;
}


export interface MutationUpdatePasswordArgs {
    input: UpdatePasswordInput;
}


export interface MutationUpdateProfileArgs {
    input: UpdateProfileInput;
}


export interface MutationVerifyEmailArgs {
    input: VerifyEmailInput;
}

/** Allows ordering a list of records. */
export interface OrderByClause {
    /** The column that is used for ordering. */
    column: Scalars['String']['input'];
    /** The direction that is used for ordering. */
    order: SortOrder;
}

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
    /** Amount of items. */
    Count = 'COUNT',
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
    /** Average. */
    Avg = 'AVG',
    /** Amount of items. */
    Count = 'COUNT',
    /** Maximum. */
    Max = 'MAX',
    /** Minimum. */
    Min = 'MIN',
    /** Sum. */
    Sum = 'SUM',
}

export interface OrganizationAuthor {
    avatar: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
}

export type Owner = Post;

export interface Post {
    attachments: Array<Attachment>;
    author: Author;
    hasNewLikes?: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['ID']['output'];
    isLiked: Scalars['Boolean']['output'];
    isOwnedByMe: Scalars['Boolean']['output'];
    message: Scalars['String']['output'];
    publicationChannel?: Maybe<Community>;
    sharedPost?: Maybe<Post>;
}

export interface PostsInput {
    communityId?: InputMaybe<Scalars['ID']['input']>;
    lastPostId?: InputMaybe<Scalars['ID']['input']>;
    profileId?: InputMaybe<Scalars['ID']['input']>;
}

export interface Profile {
    avatar: Scalars['String']['output'];
    birthdate: Scalars['Date']['output'];
    chatParticipant?: Maybe<ChatParticipant>;
    description: Scalars['String']['output'];
    follows: Array<Profile>;
    id: Scalars['ID']['output'];
    isDeleted: Scalars['Boolean']['output'];
    isFollowedByMe: Scalars['Boolean']['output'];
    isPaused: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    profileCommunities: Array<ProfileCommunity>;
    user: User;
}

export interface ProfileCommunity {
    community: Community;
    elaboration?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    profile: Profile;
}

export interface Query {
    communities: Array<Community>;
    community: Community;
    likes: Array<Profile>;
    me: User;
    post: Post;
    posts: Array<Post>;
    profile: Profile;
}


export interface QueryCommunityArgs {
    id: Scalars['ID']['input'];
}


export interface QueryLikesArgs {
    id: Scalars['ID']['input'];
}


export interface QueryPostArgs {
    id: Scalars['ID']['input'];
}


export interface QueryPostsArgs {
    input?: InputMaybe<PostsInput>;
}


export interface QueryProfileArgs {
    id: Scalars['ID']['input'];
}

export interface RegisterInput {
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
    password_confirmation: Scalars['String']['input'];
}

export interface RegisterResponse {
    status: RegisterStatus;
    token?: Maybe<Scalars['String']['output']>;
}

export enum RegisterStatus {
    /** MUST_VERIFY_EMAIL */
    MustVerifyEmail = 'MUST_VERIFY_EMAIL',
    /** SUCCESS */
    Success = 'SUCCESS',
}

export interface ReportPostInput {
    explanation: Scalars['String']['input'];
    postId: Scalars['ID']['input'];
}

export interface ReportProfileInput {
    explanation: Scalars['String']['input'];
    profileId: Scalars['ID']['input'];
}

export interface ResendEmailVerificationInput {
    email: Scalars['String']['input'];
}

export interface ResendEmailVerificationResponse {
    status: ResendEmailVerificationStatus;
}

export enum ResendEmailVerificationStatus {
    /** EMAIL_SENT */
    EmailSent = 'EMAIL_SENT',
}

export interface ResetPasswordInput {
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
    password_confirmation: Scalars['String']['input'];
    token: Scalars['String']['input'];
}

export interface ResetPasswordResponse {
    message?: Maybe<Scalars['String']['output']>;
    status: ResetPasswordStatus;
}

export enum ResetPasswordStatus {
    /** PASSWORD_RESET */
    PasswordReset = 'PASSWORD_RESET',
}

export interface SharePostInput {
    message: Scalars['String']['input'];
    publicationChannelId: Scalars['ID']['input'];
    sharedPostId: Scalars['ID']['input'];
}

/** Directions for ordering a list of records. */
export enum SortOrder {
    /** Sort records in ascending order. */
    Asc = 'ASC',
    /** Sort records in descending order. */
    Desc = 'DESC',
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
    /** Only return trashed results. */
    Only = 'ONLY',
    /** Return both trashed and non-trashed results. */
    With = 'WITH',
    /** Only return non-trashed results. */
    Without = 'WITHOUT',
}

export interface UpdateMeInput {
    email?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdatePasswordInput {
    current_password: Scalars['String']['input'];
    password: Scalars['String']['input'];
    password_confirmation: Scalars['String']['input'];
}

export interface UpdatePasswordResponse {
    status: UpdatePasswordStatus;
}

export enum UpdatePasswordStatus {
    /** PASSWORD_UPDATED */
    PasswordUpdated = 'PASSWORD_UPDATED',
}

export interface UpdateProfileInput {
    avatar?: InputMaybe<Scalars['Upload']['input']>;
    birthdate?: InputMaybe<Scalars['Date']['input']>;
    description?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
}

export interface User {
    email: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    profile?: Maybe<Profile>;
}

export interface VerifyEmailInput {
    expires?: InputMaybe<Scalars['Int']['input']>;
    hash: Scalars['String']['input'];
    id: Scalars['ID']['input'];
    signature?: InputMaybe<Scalars['String']['input']>;
}

export type ForgotPasswordMutationVariables = Exact<{
    input: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = { forgotPassword: { status: ForgotPasswordStatus, message?: string | null } };

export type LoginMutationVariables = Exact<{
    input: LoginInput;
}>;


export type LoginMutation = { login: { token: string } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { logout: { status: LogoutStatus, message: string } };

export type RegisterMutationVariables = Exact<{
    input: RegisterInput;
}>;


export type RegisterMutation = { register: { status: RegisterStatus, token?: string | null } };

export type ResendEmailVerificationMutationVariables = Exact<{
    input: ResendEmailVerificationInput;
}>;


export type ResendEmailVerificationMutation = { resendEmailVerification: { status: ResendEmailVerificationStatus } };

export type ResetPasswordMutationVariables = Exact<{
    input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { resetPassword: { status: ResetPasswordStatus, message?: string | null } };

export type VerifyEmailMutationVariables = Exact<{
    input: VerifyEmailInput;
}>;


export type VerifyEmailMutation = { verifyEmail: { status: EmailVerificationStatus, token: string } };

export type CommunityFragment = { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null };

export type CommunityWithProfileCommunitiesFragment = { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null, profileCommunities: Array<{ id: string, elaboration?: string | null, profile: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null }, community: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null } }> };

export type JoinCommunityMutationVariables = Exact<{
    id: Scalars['ID']['input'];
}>;


export type JoinCommunityMutation = { joinCommunity: { id: string, elaboration?: string | null, community: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null } } };

export type LeaveCommunityMutationVariables = Exact<{
    id: Scalars['ID']['input'];
}>;


export type LeaveCommunityMutation = { leaveCommunity: { id: string, elaboration?: string | null, community: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null } } };

export type CommunitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type CommunitiesQuery = { communities: Array<{ id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null }> };

export type CommunityQueryVariables = Exact<{
    id: Scalars['ID']['input'];
}>;


export type CommunityQuery = { community: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null, profileCommunities: Array<{ id: string, elaboration?: string | null, profile: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null }, community: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null } }> } };

export type PostFragment = { id: string, isOwnedByMe: boolean, isLiked: boolean, hasNewLikes?: boolean | null, message: string, author: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } | {}, attachments: Array<{ id: string, file: string }>, sharedPost?: { id: string, author: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } | {} } | null, publicationChannel?: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null } | null };

export type DeletePostMutationVariables = Exact<{
    input: Scalars['ID']['input'];
}>;


export type DeletePostMutation = { deletePost: boolean };

export type CreatePostMutationVariables = Exact<{
    input: CreatePostInput;
}>;


export type CreatePostMutation = { createPost: { id: string, isOwnedByMe: boolean, isLiked: boolean, hasNewLikes?: boolean | null, message: string, author: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } | {}, attachments: Array<{ id: string, file: string }>, sharedPost?: { id: string, author: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } | {} } | null, publicationChannel?: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null } | null } };

export type LikePostMutationVariables = Exact<{
    postId: Scalars['ID']['input'];
}>;


export type LikePostMutation = { likePost: { id: string, isOwnedByMe: boolean, isLiked: boolean, hasNewLikes?: boolean | null, message: string, author: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } | {}, attachments: Array<{ id: string, file: string }>, sharedPost?: { id: string, author: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } | {} } | null, publicationChannel?: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null } | null } };

export type UnlikePostMutationVariables = Exact<{
    postId: Scalars['ID']['input'];
}>;


export type UnlikePostMutation = { unlikePost: { id: string, isOwnedByMe: boolean, isLiked: boolean, hasNewLikes?: boolean | null, message: string, author: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } | {}, attachments: Array<{ id: string, file: string }>, sharedPost?: { id: string, author: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } | {} } | null, publicationChannel?: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null } | null } };

export type ReportPostMutationVariables = Exact<{
    input: ReportPostInput;
}>;


export type ReportPostMutation = { reportPost: { id: string, isOwnedByMe: boolean, isLiked: boolean, hasNewLikes?: boolean | null, message: string, author: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } | {}, attachments: Array<{ id: string, file: string }>, sharedPost?: { id: string, author: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } | {} } | null, publicationChannel?: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null } | null } };

export type SharePostMutationVariables = Exact<{
    input: SharePostInput;
}>;


export type SharePostMutation = { sharePost: { id: string, isOwnedByMe: boolean, isLiked: boolean, hasNewLikes?: boolean | null, message: string, author: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } | {}, attachments: Array<{ id: string, file: string }>, sharedPost?: { id: string, author: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } | {} } | null, publicationChannel?: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null } | null } };

export type PostQueryVariables = Exact<{
    id: Scalars['ID']['input'];
}>;


export type PostQuery = { post: { id: string, isOwnedByMe: boolean, isLiked: boolean, hasNewLikes?: boolean | null, message: string, author: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } | {}, attachments: Array<{ id: string, file: string }>, sharedPost?: { id: string, author: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } | {} } | null, publicationChannel?: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null } | null } };

export type PostsQueryVariables = Exact<{
    input: PostsInput;
}>;


export type PostsQuery = { posts: Array<{ id: string, isOwnedByMe: boolean, isLiked: boolean, hasNewLikes?: boolean | null, message: string, author: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } | {}, attachments: Array<{ id: string, file: string }>, sharedPost?: { id: string, author: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } | {} } | null, publicationChannel?: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null } | null }> };

export type LikesQueryVariables = Exact<{
    id: Scalars['ID']['input'];
}>;


export type LikesQuery = { likes: Array<{ id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null }> };

export type ProfileFragment = { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null };

export type ChatParticipantFragment = { id: string, token?: string | null };

export type ProfileCommunityFragment = { id: string, elaboration?: string | null, community: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null } };

export type ProfileWithProfileCommunitiesFragment = { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, profileCommunities: Array<{ id: string, elaboration?: string | null, community: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null } }>, chatParticipant?: { id: string } | null };

export type CreateProfileMutationVariables = Exact<{
    input: CreateProfileInput;
}>;


export type CreateProfileMutation = { createProfile: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string, token?: string | null } | null } };

export type PauseProfileMutationVariables = Exact<{ [key: string]: never; }>;


export type PauseProfileMutation = { pauseProfile: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } };

export type ResumeProfileMutationVariables = Exact<{ [key: string]: never; }>;


export type ResumeProfileMutation = { resumeProfile: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } };

export type UpdateProfileMutationVariables = Exact<{
    input: UpdateProfileInput;
}>;


export type UpdateProfileMutation = { updateProfile: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } };

export type FollowProfileMutationVariables = Exact<{
    id: Scalars['ID']['input'];
}>;


export type FollowProfileMutation = { followProfile: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } };

export type UnfollowProfileMutationVariables = Exact<{
    id: Scalars['ID']['input'];
}>;


export type UnfollowProfileMutation = { unfollowProfile: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } };

export type ReportProfileMutationVariables = Exact<{
    input: ReportProfileInput;
}>;


export type ReportProfileMutation = { reportProfile: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null } };

export type ProfileQueryVariables = Exact<{
    id: Scalars['ID']['input'];
}>;


export type ProfileQuery = { profile: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, user: { id: string, email: string }, profileCommunities: Array<{ id: string, elaboration?: string | null, community: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null } }>, chatParticipant?: { id: string } | null } };

export type UserFragment = { id: string, email: string };

export type UserWithProfileFragment = { id: string, email: string, profile?: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string, token?: string | null } | null, follows: Array<{ id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null }>, profileCommunities: Array<{ id: string, elaboration?: string | null, community: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null } }> } | null };

export type DeleteMeMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteMeMutation = { deleteMe: boolean };

export type UpdateMeMutationVariables = Exact<{
    input: UpdateMeInput;
}>;


export type UpdateMeMutation = { updateMe: { id: string, email: string } };

export type UpdatePasswordMutationVariables = Exact<{
    input: UpdatePasswordInput;
}>;


export type UpdatePasswordMutation = { updatePassword: { status: UpdatePasswordStatus } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { me: { id: string, email: string, profile?: { id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string, token?: string | null } | null, follows: Array<{ id: string, avatar: string, birthdate: any, description: string, isDeleted: boolean, isPaused: boolean, isFollowedByMe: boolean, name: string, chatParticipant?: { id: string } | null }>, profileCommunities: Array<{ id: string, elaboration?: string | null, community: { id: string, name: string, description: string, icon: string, isJoinedByMe?: boolean | null } }> } | null } };

export const CommunityFragmentDoc = gql`
    fragment Community on Community {
  id
  name
  description
  icon
  isJoinedByMe
}
    `;
export const ProfileCommunityFragmentDoc = gql`
    fragment ProfileCommunity on ProfileCommunity {
  id
  community {
    ...Community
  }
  elaboration
}
    ${CommunityFragmentDoc}`;
export const ProfileFragmentDoc = gql`
    fragment Profile on Profile {
  id
  avatar
  birthdate
  description
  isDeleted
  isPaused
  isFollowedByMe
  name
  chatParticipant {
    id
  }
}
    `;
export const CommunityWithProfileCommunitiesFragmentDoc = gql`
    fragment CommunityWithProfileCommunities on Community {
  ...Community
  profileCommunities {
    ...ProfileCommunity
    profile {
      ...Profile
    }
  }
}
    ${CommunityFragmentDoc}
${ProfileCommunityFragmentDoc}
${ProfileFragmentDoc}`;
export const PostFragmentDoc = gql`
    fragment Post on Post {
  id
  isOwnedByMe
  isLiked
  hasNewLikes
  message
  author {
    ...Profile
  }
  attachments {
    id
    file
  }
  sharedPost {
    id
    author {
      ...Profile
    }
  }
  publicationChannel {
    ...Community
  }
}
    ${ProfileFragmentDoc}
${CommunityFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  email
}
    `;
export const ProfileWithProfileCommunitiesFragmentDoc = gql`
    fragment ProfileWithProfileCommunities on Profile {
  ...Profile
  profileCommunities {
    ...ProfileCommunity
  }
}
    ${ProfileFragmentDoc}
${ProfileCommunityFragmentDoc}`;
export const ChatParticipantFragmentDoc = gql`
    fragment ChatParticipant on ChatParticipant {
  id
  token
}
    `;
export const UserWithProfileFragmentDoc = gql`
    fragment UserWithProfile on User {
  ...User
  profile {
    ...ProfileWithProfileCommunities
    chatParticipant {
      ...ChatParticipant
    }
    follows {
      ...Profile
    }
  }
}
    ${UserFragmentDoc}
${ProfileWithProfileCommunitiesFragmentDoc}
${ChatParticipantFragmentDoc}
${ProfileFragmentDoc}`;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input) {
    status
    message
  }
}
    `;

@Injectable({
    providedIn: 'root',
})
export class ForgotPasswordMutationService extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
    override document = ForgotPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    token
  }
}
    `;

@Injectable({
    providedIn: 'root',
})
export class LoginMutationService extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    override document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LogoutDocument = gql`
    mutation logout {
  logout {
    status
    message
  }
}
    `;

@Injectable({
    providedIn: 'root',
})
export class LogoutMutationService extends Apollo.Mutation<LogoutMutation, LogoutMutationVariables> {
    override document = LogoutDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const RegisterDocument = gql`
    mutation register($input: RegisterInput!) {
  register(input: $input) {
    status
    token
  }
}
    `;

@Injectable({
    providedIn: 'root',
})
export class RegisterMutationService extends Apollo.Mutation<RegisterMutation, RegisterMutationVariables> {
    override document = RegisterDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ResendEmailVerificationDocument = gql`
    mutation resendEmailVerification($input: ResendEmailVerificationInput!) {
  resendEmailVerification(input: $input) {
    status
  }
}
    `;

@Injectable({
    providedIn: 'root',
})
export class ResendEmailVerificationMutationService extends Apollo.Mutation<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables> {
    override document = ResendEmailVerificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    status
    message
  }
}
    `;

@Injectable({
    providedIn: 'root',
})
export class ResetPasswordMutationService extends Apollo.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> {
    override document = ResetPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const VerifyEmailDocument = gql`
    mutation verifyEmail($input: VerifyEmailInput!) {
  verifyEmail(input: $input) {
    status
    token
  }
}
    `;

@Injectable({
    providedIn: 'root',
})
export class VerifyEmailMutationService extends Apollo.Mutation<VerifyEmailMutation, VerifyEmailMutationVariables> {
    override document = VerifyEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const JoinCommunityDocument = gql`
    mutation joinCommunity($id: ID!) {
  joinCommunity(id: $id) {
    ...ProfileCommunity
  }
}
    ${ProfileCommunityFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class JoinCommunityMutationService extends Apollo.Mutation<JoinCommunityMutation, JoinCommunityMutationVariables> {
    override document = JoinCommunityDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LeaveCommunityDocument = gql`
    mutation leaveCommunity($id: ID!) {
  leaveCommunity(id: $id) {
    ...ProfileCommunity
  }
}
    ${ProfileCommunityFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class LeaveCommunityMutationService extends Apollo.Mutation<LeaveCommunityMutation, LeaveCommunityMutationVariables> {
    override document = LeaveCommunityDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CommunitiesDocument = gql`
    query communities {
  communities {
    ...Community
  }
}
    ${CommunityFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class CommunitiesQueryService extends Apollo.Query<CommunitiesQuery, CommunitiesQueryVariables> {
    override document = CommunitiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CommunityDocument = gql`
    query community($id: ID!) {
  community(id: $id) {
    ...CommunityWithProfileCommunities
  }
}
    ${CommunityWithProfileCommunitiesFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class CommunityQueryService extends Apollo.Query<CommunityQuery, CommunityQueryVariables> {
    override document = CommunityDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeletePostDocument = gql`
    mutation deletePost($input: ID!) {
  deletePost(id: $input)
}
    `;

@Injectable({
    providedIn: 'root',
})
export class DeletePostMutationService extends Apollo.Mutation<DeletePostMutation, DeletePostMutationVariables> {
    override document = DeletePostDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreatePostDocument = gql`
    mutation createPost($input: CreatePostInput!) {
  createPost(input: $input) {
    ...Post
  }
}
    ${PostFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class CreatePostMutationService extends Apollo.Mutation<CreatePostMutation, CreatePostMutationVariables> {
    override document = CreatePostDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LikePostDocument = gql`
    mutation likePost($postId: ID!) {
  likePost(id: $postId) {
    ...Post
  }
}
    ${PostFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class LikePostMutationService extends Apollo.Mutation<LikePostMutation, LikePostMutationVariables> {
    override document = LikePostDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UnlikePostDocument = gql`
    mutation unlikePost($postId: ID!) {
  unlikePost(id: $postId) {
    ...Post
  }
}
    ${PostFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class UnlikePostMutationService extends Apollo.Mutation<UnlikePostMutation, UnlikePostMutationVariables> {
    override document = UnlikePostDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ReportPostDocument = gql`
    mutation reportPost($input: ReportPostInput!) {
  reportPost(input: $input) {
    ...Post
  }
}
    ${PostFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class ReportPostMutationService extends Apollo.Mutation<ReportPostMutation, ReportPostMutationVariables> {
    override document = ReportPostDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const SharePostDocument = gql`
    mutation sharePost($input: SharePostInput!) {
  sharePost(input: $input) {
    ...Post
  }
}
    ${PostFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class SharePostMutationService extends Apollo.Mutation<SharePostMutation, SharePostMutationVariables> {
    override document = SharePostDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const PostDocument = gql`
    query post($id: ID!) {
  post(id: $id) {
    ...Post
  }
}
    ${PostFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class PostQueryService extends Apollo.Query<PostQuery, PostQueryVariables> {
    override document = PostDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const PostsDocument = gql`
    query posts($input: PostsInput!) {
  posts(input: $input) {
    ...Post
  }
}
    ${PostFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class PostsQueryService extends Apollo.Query<PostsQuery, PostsQueryVariables> {
    override document = PostsDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const LikesDocument = gql`
    query likes($id: ID!) {
  likes(id: $id) {
    ...Profile
  }
}
    ${ProfileFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class LikesQueryService extends Apollo.Query<LikesQuery, LikesQueryVariables> {
    override document = LikesDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const CreateProfileDocument = gql`
    mutation createProfile($input: CreateProfileInput!) {
  createProfile(input: $input) {
    ...Profile
    chatParticipant {
      ...ChatParticipant
    }
  }
}
    ${ProfileFragmentDoc}
${ChatParticipantFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class CreateProfileMutationService extends Apollo.Mutation<CreateProfileMutation, CreateProfileMutationVariables> {
    override document = CreateProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const PauseProfileDocument = gql`
    mutation pauseProfile {
  pauseProfile {
    ...Profile
  }
}
    ${ProfileFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class PauseProfileMutationService extends Apollo.Mutation<PauseProfileMutation, PauseProfileMutationVariables> {
    override document = PauseProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ResumeProfileDocument = gql`
    mutation resumeProfile {
  resumeProfile {
    ...Profile
  }
}
    ${ProfileFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class ResumeProfileMutationService extends Apollo.Mutation<ResumeProfileMutation, ResumeProfileMutationVariables> {
    override document = ResumeProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateProfileDocument = gql`
    mutation updateProfile($input: UpdateProfileInput!) {
  updateProfile(input: $input) {
    ...Profile
  }
}
    ${ProfileFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class UpdateProfileMutationService extends Apollo.Mutation<UpdateProfileMutation, UpdateProfileMutationVariables> {
    override document = UpdateProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const FollowProfileDocument = gql`
    mutation followProfile($id: ID!) {
  followProfile(id: $id) {
    ...Profile
  }
}
    ${ProfileFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class FollowProfileMutationService extends Apollo.Mutation<FollowProfileMutation, FollowProfileMutationVariables> {
    override document = FollowProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UnfollowProfileDocument = gql`
    mutation unfollowProfile($id: ID!) {
  unfollowProfile(id: $id) {
    ...Profile
  }
}
    ${ProfileFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class UnfollowProfileMutationService extends Apollo.Mutation<UnfollowProfileMutation, UnfollowProfileMutationVariables> {
    override document = UnfollowProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ReportProfileDocument = gql`
    mutation reportProfile($input: ReportProfileInput!) {
  reportProfile(input: $input) {
    ...Profile
  }
}
    ${ProfileFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class ReportProfileMutationService extends Apollo.Mutation<ReportProfileMutation, ReportProfileMutationVariables> {
    override document = ReportProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const ProfileDocument = gql`
    query profile($id: ID!) {
  profile(id: $id) {
    ...ProfileWithProfileCommunities
    user {
      ...User
    }
  }
}
    ${ProfileWithProfileCommunitiesFragmentDoc}
${UserFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class ProfileQueryService extends Apollo.Query<ProfileQuery, ProfileQueryVariables> {
    override document = ProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const DeleteMeDocument = gql`
    mutation deleteMe {
  deleteMe
}
    `;

@Injectable({
    providedIn: 'root',
})
export class DeleteMeMutationService extends Apollo.Mutation<DeleteMeMutation, DeleteMeMutationVariables> {
    override document = DeleteMeDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdateMeDocument = gql`
    mutation updateMe($input: UpdateMeInput!) {
  updateMe(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class UpdateMeMutationService extends Apollo.Mutation<UpdateMeMutation, UpdateMeMutationVariables> {
    override document = UpdateMeDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const UpdatePasswordDocument = gql`
    mutation updatePassword($input: UpdatePasswordInput!) {
  updatePassword(input: $input) {
    status
  }
}
    `;

@Injectable({
    providedIn: 'root',
})
export class UpdatePasswordMutationService extends Apollo.Mutation<UpdatePasswordMutation, UpdatePasswordMutationVariables> {
    override document = UpdatePasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
export const MeDocument = gql`
    query me {
  me {
    ...UserWithProfile
  }
}
    ${UserWithProfileFragmentDoc}`;

@Injectable({
    providedIn: 'root',
})
export class MeQueryService extends Apollo.Query<MeQuery, MeQueryVariables> {
    override document = MeDocument;
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}