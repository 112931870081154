import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { clone } from 'ramda'
import { BehaviorSubject, firstValueFrom, map } from 'rxjs'

import {
    Community,
    DeleteMeMutation,
    DeleteMeMutationService,
    FollowProfileMutation,
    FollowProfileMutationService,
    MeQuery,
    MeQueryService,
    Profile,
    ProfileQuery,
    ProfileQueryService,
    UnfollowProfileMutation,
    UnfollowProfileMutationService,
    UpdateMeInput,
    UpdateMeMutation,
    UpdateMeMutationService,
    UpdatePasswordInput,
    UpdatePasswordMutation,
    UpdatePasswordMutationService,
    User,
} from '@app-graphql'
import { ApiHelperService, CacheOptions } from '@app-services/api/api-helper.service'

@Injectable({
    providedIn: 'root',
})
export class UserService {

    public user$ = new BehaviorSubject<Partial<User>>(null)
    public initialized = false

    private user: Partial<User>

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly deleteMeMutationService: DeleteMeMutationService,
        private readonly followProfileMutationService: FollowProfileMutationService,
        private readonly meQueryService: MeQueryService,
        private readonly profileQueryService: ProfileQueryService,
        private readonly unfollowProfileMutationService: UnfollowProfileMutationService,
        private readonly updateMeMutationService: UpdateMeMutationService,
        private readonly updatePasswordMutationService: UpdatePasswordMutationService,
    ) {
    }

    public async initialize(): Promise<void> {
        if (this.initialized) {
            return
        }

        await this.getUser()

        this.initialized = true
    }

    public async getUser(cacheOptions?: CacheOptions): Promise<Partial<User> | null> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, 'user')
        const user$ = this.meQueryService.fetch(null, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<MeQuery>) => {
                this.user = result.data.me as Partial<User>
                this.user$.next(this.user)
                return this.user
            }),
        )

        try {
            return await firstValueFrom(user$)
        } catch (e) {
            await this.apiHelperService.showHttpError()
        }
    }

    public async deleteMe(): Promise<DeleteMeMutation> {
        try {
            const response = await firstValueFrom(
                this.deleteMeMutationService.mutate(),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async updateMe(input: UpdateMeInput): Promise<UpdateMeMutation> {
        try {
            const response = await firstValueFrom(
                this.updateMeMutationService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async updatePassword(input: UpdatePasswordInput): Promise<UpdatePasswordMutation> {
        try {
            const response = await firstValueFrom(
                this.updatePasswordMutationService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async followProfile(id: string): Promise<FollowProfileMutation> {
        try {
            const response = await firstValueFrom(
                this.followProfileMutationService.mutate(
                    { id },
                    {
                        update: (store, { data: { followProfile } }) => {

                            // Update the 'me' query cache
                            const meData = clone(store.readQuery<MeQuery>({ query: this.meQueryService.document }))
                            meData?.me?.profile?.follows?.push(followProfile)
                            store.writeQuery({ query: this.meQueryService.document, data: meData })
                            this.user$.next(meData?.me as Partial<User>)

                            // Update the profile query cache
                            const profileData = clone(store.readQuery<ProfileQuery>({
                                query: this.profileQueryService.document,
                                variables: { id },
                            }))
                            if (profileData?.profile) {
                                profileData.profile.isFollowedByMe = true
                                store.writeQuery({ query: this.profileQueryService.document, data: profileData })
                            }
                        },
                    },
                ),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async unfollowProfile(id: string): Promise<UnfollowProfileMutation> {
        try {
            const response = await firstValueFrom(
                this.unfollowProfileMutationService.mutate(
                    { id },
                    {
                        update: (store, { data: { unfollowProfile } }) => {

                            // Update the 'me' query cache
                            const meData = clone(store.readQuery<MeQuery>({ query: this.meQueryService.document }))
                            meData.me.profile.follows = meData.me?.profile?.follows?.filter(
                                (follow: Partial<Profile>) => follow.id !== unfollowProfile.id,
                            ) ?? []
                            store.writeQuery({ query: this.meQueryService.document, data: meData })
                            this.user$.next(meData?.me as Partial<User>)

                            // Update the profile query cache
                            const profileData = clone(store.readQuery<ProfileQuery>({
                                query: this.profileQueryService.document,
                                variables: { id },
                            }))
                            if (profileData?.profile) {
                                profileData.profile.isFollowedByMe = true
                                store.writeQuery({ query: this.profileQueryService.document, data: profileData })
                            }
                        },
                    },
                ),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async communityHasMatch(community: Partial<Community>): Promise<boolean> {
        if (! this.user) {
            await this.getUser()
        }
        return !! this.user.profile?.profileCommunities
            ?.find((profileCommunity) => profileCommunity.community.id === community?.id)
    }

    public async isProfileFollowedByMe(profile: Partial<Profile>): Promise<boolean> {
        if (! this.user) {
            await this.getUser()
        }
        return !! this.user.profile?.follows?.find((follow) => follow.id === profile?.id)
    }

    public async profileIsMe(profile: Partial<Profile>): Promise<boolean> {
        if (! this.user) {
            await this.getUser()
        }
        return this.user.profile?.id === profile?.id
    }

    public async userIsMe(user: Partial<User>): Promise<boolean> {
        if (! this.user) {
            await this.getUser()
        }
        return this.user.id === user?.id
    }

    public async userIsPaused(): Promise<boolean> {
        if (! this.user) {
            await this.getUser()
        }
        return this.user.profile?.isPaused
    }

    public async getChatParticipantId(): Promise<string> {
        if (! this.user) {
            await this.getUser()
        }
        return this.user.profile?.chatParticipant?.id
    }

}
