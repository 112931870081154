import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root',
})
export class PageHelperService {

    public getCurrentPage(): HTMLElement {

        // Get the current page and check if it 'can go back'
        const visiblePages = document.querySelectorAll<HTMLElement>(
            '.ion-page'
            + ':not(ion-app)'
            + ':not(.ion-page-hidden)'
            + ':not(app-tabs-page)'
            + ':not(app-tabs-page.ion-page-hidden .ion-page)'
            + ':not(ion-modal .ion-page)',
        )

        return visiblePages.length === 1
            ? visiblePages[0]
            : Array.from(visiblePages).find((page) => {
                const tabs = page.closest('app-tabs-page')
                return ! tabs || ! tabs.classList.contains('ion-page-hidden')
            })
    }

    public scrollCurrentPageToTop(behavior: ScrollBehavior = 'auto'): void {
        const currentPage = this.getCurrentPage()
        currentPage?.querySelector('ion-content')?.shadowRoot?.querySelector('.inner-scroll')?.scrollTo({
            top: 0,
            behavior,
        })
    }

}
