import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'

import { AppConfig } from '@app-config'

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {

    constructor(
        private readonly translateService: TranslateService,
    ) {
        //
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request.clone({
            setHeaders: {
                'Accept-Language':
                    this.translateService.currentLang
                    || this.translateService.defaultLang
                    || AppConfig.locales[0].code,
            },
        }))
    }

}
