import { Injectable } from '@angular/core'
import { Keyboard } from '@capacitor/keyboard'

@Injectable({
    providedIn: 'root',
})
export class KeyboardHelperService {

    private rootElement: HTMLElement

    public initialize(): void {
        this.rootElement = document.querySelector<HTMLElement>(':root')

        Keyboard.addListener('keyboardWillShow', (e) => {
            this.rootElement.style.setProperty('--keyboard-height', `${e.keyboardHeight}px`)
            document.documentElement.classList.add('keyboard-visible')
        })

        Keyboard.addListener('keyboardWillHide', () => {
            this.rootElement.style.setProperty('--keyboard-height', '0px')
            document.documentElement.classList.remove('keyboard-visible')
        })

        // Scroll focused form elements into view
        Keyboard.addListener('keyboardDidShow', () => {
            if (
                (
                    document.activeElement instanceof HTMLInputElement
                    || document.activeElement instanceof HTMLTextAreaElement
                )
                && ! document.activeElement
                    ?.parentElement?.parentElement?.parentElement
                    ?.classList.contains('manual-focus')
            ) {
                document.activeElement.scrollIntoView({
                    block: 'center',
                })
            }
        })
    }

}
