import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { clone } from 'ramda'
import { firstValueFrom, map, Subject } from 'rxjs'

import {
    Community,
    CommunitiesQuery,
    CommunitiesQueryService,
    CommunityQueryService,
    CommunityQuery,
    JoinCommunityMutationService,
    LeaveCommunityMutationService,
    JoinCommunityMutation,
    LeaveCommunityMutation,
    MeQueryService,
    MeQuery,
} from '@app-graphql'
import { ApiHelperService, CacheOptions } from '@app-services/api/api-helper.service'

@Injectable({
    providedIn: 'root',
})
export class CommunityService {

    public communities$ = new Subject<Partial<Community>[]>()
    public community$ = new Subject<Partial<Community>>()

    private communities: Partial<Community>[]
    private community: Partial<Community>

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly communitiesQueryService: CommunitiesQueryService,
        private readonly communityQueryService: CommunityQueryService,
        private readonly joinCommunityMutationService: JoinCommunityMutationService,
        private readonly leaveCommunityMutationService: LeaveCommunityMutationService,
        private readonly meQueryService: MeQueryService,
    ) {
    }

    public async getCommunityById(id: string, cacheOptions?: CacheOptions): Promise<Partial<Community> | null> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, `communities.community_${id}`)
        const community$ = this.communityQueryService.fetch({ id }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<CommunityQuery>) => {
                this.community = result.data.community as Partial<Community>
                this.community$.next(this.community)
                return this.community
            }),
        )

        try {
            return await firstValueFrom(community$)
        } catch (e) {
            await this.apiHelperService.showHttpError()
        }
    }

    public async getCommunities(cacheOptions?: CacheOptions): Promise<Partial<Community>[] | null> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, 'communities')
        const communities$ = this.communitiesQueryService.fetch(null, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<CommunitiesQuery>) => {
                this.communities = result.data.communities as Partial<Community>[]
                this.communities$.next(this.communities)
                return this.communities
            }),
        )

        try {
            return await firstValueFrom(communities$)
        } catch (e) {
            await this.apiHelperService.showHttpError()
        }
    }

    public async joinCommunity(id: string): Promise<JoinCommunityMutation> {
        try {
            const response = await firstValueFrom(
                this.joinCommunityMutationService.mutate(
                    { id },
                    {
                        update: (store, { data: { joinCommunity } }) => {
                            const data = clone(store.readQuery<MeQuery>({ query: this.meQueryService.document }))
                            data?.me?.profile?.profileCommunities?.push(joinCommunity)
                            store.writeQuery({ query: this.meQueryService.document, data })
                        },
                    },
                ),
            )

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async leaveCommunity(id: string): Promise<LeaveCommunityMutation> {
        try {
            const response = await firstValueFrom(
                this.leaveCommunityMutationService.mutate(
                    { id },
                    {
                        update: (store, { data: { leaveCommunity } }) => {
                            const data = clone(store.readQuery<MeQuery>({ query: this.meQueryService.document }))
                            data.me.profile.profileCommunities = data.me?.profile?.profileCommunities?.filter(
                                (community: Partial<Community>) => community.id !== leaveCommunity.id,
                            ) ?? []
                            store.writeQuery({ query: this.meQueryService.document, data })
                        },
                    },
                ),
            )

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

}
