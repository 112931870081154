import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { firstValueFrom, map, Subject } from 'rxjs'

import {
    CreateProfileInput,
    CreateProfileMutation,
    CreateProfileMutationService,
    PauseProfileMutation,
    PauseProfileMutationService,
    Profile,
    ProfileQuery,
    ProfileQueryService,
    ReportProfileInput,
    ReportProfileMutation,
    ReportProfileMutationService,
    ResumeProfileMutation,
    ResumeProfileMutationService,
    UpdateProfileInput,
    UpdateProfileMutation,
    UpdateProfileMutationService,
} from '@app-graphql'
import { ApiHelperService, CacheOptions } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class ProfileService {

    public profile$ = new Subject<Partial<Profile>>()

    private profile: Partial<Profile>

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly createProfileMutationService: CreateProfileMutationService,
        private readonly pauseProfileMutationService: PauseProfileMutationService,
        private readonly profileQueryService: ProfileQueryService,
        private readonly reportProfileMutationService: ReportProfileMutationService,
        private readonly resumeProfileMutationService: ResumeProfileMutationService,
        private readonly updateProfileMutationService: UpdateProfileMutationService,
    ) {
    }

    public async getProfileById(id: string, cacheOptions?: CacheOptions): Promise<Partial<Profile> | null> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, `profile.${id}}`)
        const profile$ = this.profileQueryService.fetch({ id }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<ProfileQuery>) => {
                this.profile = result.data.profile as Partial<Profile>
                this.profile$.next(this.profile)
                return this.profile
            }),
        )

        try {
            return await firstValueFrom(profile$)
        } catch (e) {
            await this.apiHelperService.showHttpError()
        }
    }

    public async createProfile(input: CreateProfileInput): Promise<CreateProfileMutation> {
        try {
            const response = await firstValueFrom(
                this.createProfileMutationService.mutate(
                    { input },
                    { context: { useMultipart: true } },
                ),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async pauseProfile(): Promise<PauseProfileMutation> {
        try {
            const response = await firstValueFrom(
                this.pauseProfileMutationService.mutate(),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async resumeProfile(): Promise<ResumeProfileMutation> {
        try {
            const response = await firstValueFrom(
                this.resumeProfileMutationService.mutate(),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async updateProfile(input: UpdateProfileInput): Promise<UpdateProfileMutation> {
        try {
            const response = await firstValueFrom(
                this.updateProfileMutationService.mutate(
                    { input },
                    { context: { useMultipart: true } },
                ),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async reportProfile(input: ReportProfileInput): Promise<ReportProfileMutation> {
        try {
            const response = await firstValueFrom(
                this.reportProfileMutationService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

}
