import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

export interface PageCanDeactivate {
    canDeactivate(nextRoute: string): boolean;
}

@Injectable({
    providedIn: 'root',
})
export class CanDeactivateGuard {

    // noinspection JSUnusedGlobalSymbols
    public async canDeactivate(
        component: PageCanDeactivate,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot,
    ): Promise<boolean> {
        return component.canDeactivate(nextState.url)
    }

}
