import { Injectable, NgZone } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms'
import { Keyboard } from '@capacitor/keyboard'
import { AlertController, Platform } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { lastValueFrom } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class FormHelperService {

    constructor(
        private readonly alertController: AlertController,
        private readonly platform: Platform,
        private readonly translateService: TranslateService,
        private readonly zone: NgZone,
    ) {
        //
    }

    public confirmPasswordValidator(control: AbstractControl): ValidatorFn {
        const password = control?.get('password')?.value
        const confirmPassword = control?.get('confirmPassword')?.value
        const currentErrors = control?.get('confirmPassword')?.errors
        const confirmControl = control?.get('confirmPassword')

        if (password !== confirmPassword) {
            confirmControl?.setErrors({ ...currentErrors, notMatching: true })
            return () => ({ notMatching: true })
        } else {
            confirmControl?.setErrors(currentErrors!)
        }

        return () => null
    }

    public async reportFormErrors(formGroup: FormGroup | AbstractControl[]): Promise<void> {
        const controls: (FormControl | FormGroup | AbstractControl)[] = formGroup instanceof FormGroup
            ? Object.values(formGroup.controls)
            : formGroup
        controls.forEach((control: FormControl | FormGroup | AbstractControl) => {
            if (control instanceof FormGroup) {
                this.reportFormErrors(control)
            } else {
                control.markAsTouched()
            }
        })
        await this.scrollFirstErrorIntoView()
    }

    public async scrollFirstErrorIntoView(duration = 600): Promise<void> {
        const firstErrorElement = document
            .querySelector<HTMLElement>('.ion-invalid,ion-input.ng-invalid,ion-textarea.ng-invalid,.error-message')

        if (firstErrorElement) {
            await this.scrollElementIntoView(firstErrorElement, duration)
        }
    }

    public async scrollElementIntoView(element: HTMLElement, duration = 600): Promise<void> {
        await lastValueFrom(this.zone.onStable)

        // Check if there is an open modal
        let page: HTMLElement | null = document
            .querySelector<HTMLElement>('.show-modal .ion-page:not(.ion-page-hidden)')
        if (! page) {

            // Check if there is a visible page
            const visiblePages = document.querySelectorAll<HTMLElement>(
                '.ion-page:not(ion-app):not(app-tabs):not(.ion-page-hidden)',
            )
            page = Array.from(visiblePages).find((p) => {
                const tabs = p.closest('app-tabs')
                return ! tabs || ! tabs.classList.contains('ion-page-hidden')
            }) || null
        }

        const content = page?.querySelector('ion-content') as HTMLIonContentElement
        if (! content) {
            return
        }
        const scrollElement = await content.getScrollElement()
        const containerHeight = content.offsetHeight

        const rect = element.getBoundingClientRect()
        const pos = Math.round(rect.top - ((containerHeight - rect.height) / 2))
        const scrollTop = pos + scrollElement.scrollTop

        await content.scrollToPoint(0, scrollTop, duration)
    }

    public async hideKeyboard(): Promise<void> {
        if (this.platform.is('hybrid')) {
            await Keyboard.hide()
        }
    }

    public async confirmDiscardChanges(): Promise<boolean> {
        const confirm = await this.alertController.create({
            message: this.translateService.instant('common.confirmDiscardChanges'),
            buttons: [
                {
                    text: this.translateService.instant('common.cancel'),
                    role: 'cancel',
                },
                {
                    text: this.translateService.instant('common.discardChanges'),
                    role: 'destructive',
                },
            ],
        })
        await confirm.present()
        const response = await confirm.onDidDismiss()

        return response.role === 'destructive'
    }

}
