import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { firstValueFrom, map, Subject } from 'rxjs'

import {
    CreatePostInput,
    CreatePostMutation,
    CreatePostMutationService,
    DeletePostMutation,
    DeletePostMutationService,
    LikePostMutation,
    LikePostMutationService,
    LikesQuery,
    LikesQueryService,
    Post,
    PostQuery,
    PostQueryService,
    PostsInput,
    PostsQuery,
    PostsQueryService,
    Profile,
    ReportPostInput,
    ReportPostMutation,
    ReportPostMutationService,
    SharePostInput,
    SharePostMutation,
    SharePostMutationService,
    UnlikePostMutation,
    UnlikePostMutationService,
} from '@app-graphql'
import { RequiredField } from '@app-interfaces'
import { ApiHelperService, CacheOptions } from '@app-services/api/api-helper.service'

@Injectable({
    providedIn: 'root',
})
export class PostsService {

    public post$ = new Subject<Partial<Post>>()
    public homePosts$ = new Subject<Partial<Post>[]>()
    public communityPosts$ = new Subject<Partial<Post>[]>()
    public profilePosts$ = new Subject<Partial<Post>[]>()
    public myPosts$ = new Subject<Partial<Post>[]>()
    public likes$ = new Subject<Partial<Profile>[]>()

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly createPostMutationService: CreatePostMutationService,
        private readonly deletePostMutationService: DeletePostMutationService,
        private readonly likePostMutationService: LikePostMutationService,
        private readonly likesQueryService: LikesQueryService,
        private readonly postQueryService: PostQueryService,
        private readonly postsQueryService: PostsQueryService,
        private readonly reportPostMutationService: ReportPostMutationService,
        private readonly sharePostMutationService: SharePostMutationService,
        private readonly unlikePostMutationService: UnlikePostMutationService,
    ) {
    }

    public async getPostById(id: string, cacheOptions?: CacheOptions): Promise<Partial<Post> | null> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `posts.post_${id}`,
        )
        const post$ = this.postQueryService.fetch({ id }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<PostQuery>) => {
                const post = result.data.post as Partial<Post>
                this.post$.next(post)
                return post
            }),
        )

        try {
            return await firstValueFrom(post$)
        } catch (e) {
            await this.apiHelperService.showHttpError()
        }
    }

    public async getLikes(id: string, cacheOptions?: CacheOptions): Promise<Partial<Profile>[]> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `posts.likes_${id}`,
        )
        const posts$ = this.likesQueryService.fetch({ id }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<LikesQuery>) => {
                const likes = result.data.likes as Partial<Profile>[]
                this.likes$.next(likes)
                return likes
            }),
        )

        try {
            return await firstValueFrom(posts$)
        } catch (e) {
            await this.apiHelperService.showHttpError()
        }
    }

    public async getHomePosts(input: PostsInput, cacheOptions?: CacheOptions): Promise<Partial<Post>[] | null> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            'posts.home',
        )

        const posts$ = this.postsQueryService.fetch({ input }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<PostsQuery>) => {
                const posts = result.data.posts as Partial<Post>[]
                this.homePosts$.next(posts)
                return posts
            }),
        )

        try {
            return await firstValueFrom(posts$)
        } catch (e) {
            await this.apiHelperService.showHttpError()
        }
    }

    public async getCommunityPosts(
        input: RequiredField<PostsInput, 'communityId'>,
        cacheOptions?: CacheOptions,
    ): Promise<Partial<Post>[] | null> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `posts.community_${input.communityId}`,
        )
        const posts$ = this.postsQueryService.fetch({ input }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<PostsQuery>) => {
                const posts = result.data.posts as Partial<Post>[]
                this.communityPosts$.next(posts)
                return posts
            }),
        )

        try {
            return await firstValueFrom(posts$)
        } catch (e) {
            await this.apiHelperService.showHttpError()
        }
    }

    public async getProfilePosts(
        input: RequiredField<PostsInput, 'profileId'>,
        cacheOptions?: CacheOptions,
    ): Promise<Partial<Post>[] | null> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `posts.profile_${input.profileId}`,
        )
        const posts$ = this.postsQueryService.fetch({ input }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<PostsQuery>) => {
                const posts = result.data.posts as Partial<Post>[]
                this.profilePosts$.next(posts)
                return posts
            }),
        )

        try {
            return await firstValueFrom(posts$)
        } catch (e) {
            await this.apiHelperService.showHttpError()
        }
    }

    public async getMyPosts(
        input: RequiredField<PostsInput, 'profileId'>,
        cacheOptions?: CacheOptions,
    ): Promise<Partial<Post>[] | null> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            'posts.profile_me',
        )
        const posts$ = this.postsQueryService.fetch({ input }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<PostsQuery>) => {
                const posts = result.data.posts as Partial<Post>[]
                this.myPosts$.next(posts)
                return posts
            }),
        )

        try {
            return await firstValueFrom(posts$)
        } catch (e) {
            await this.apiHelperService.showHttpError()
        }
    }

    public async createPost(input: CreatePostInput): Promise<CreatePostMutation> {
        try {
            const response = await firstValueFrom(
                this.createPostMutationService.mutate(
                    { input },
                    { context: { useMultipart: true } },
                ),
            )

            await this.apiHelperService.invalidateCache('posts')

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async deletePost(postId: string): Promise<DeletePostMutation> {
        try {
            const response = await firstValueFrom(
                this.deletePostMutationService.mutate({ input: postId }),
            )

            await this.apiHelperService.invalidateCache('posts')

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async likePost(postId: string): Promise<LikePostMutation> {
        try {
            const response = await firstValueFrom(
                this.likePostMutationService.mutate({ postId }),
            )

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async unlikePost(postId: string): Promise<UnlikePostMutation> {
        try {
            const response = await firstValueFrom(
                this.unlikePostMutationService.mutate({ postId }),
            )

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async reportPost(input: ReportPostInput): Promise<ReportPostMutation> {
        try {
            const response = await firstValueFrom(
                this.reportPostMutationService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async sharePost(input: SharePostInput): Promise<SharePostMutation> {
        try {
            const response = await firstValueFrom(
                this.sharePostMutationService.mutate({ input }),
            );

            await this.apiHelperService.invalidateCache('posts');

            return response.data;
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e));
        }
    }

}
