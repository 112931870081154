import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { NavController } from '@ionic/angular'

import { AuthService } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class AlreadyLoggedInGuard {

    constructor(
        private readonly authService: AuthService,
        private navController: NavController,
    ) {
        //
    }

    public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        // Skip if we're redirecting to 'logout'
        if (route.queryParams?.logout !== undefined) {
            return true
        }

        const isAuthenticated = await this.authService.isAuthenticated()
        if (! isAuthenticated || this.authService.userWasRedirectedAsAlreadyLoggedIn) {
            return true
        }

        this.authService.userWasRedirectedAsAlreadyLoggedIn = true

        await this.navController.navigateRoot('/', { replaceUrl: true })

        return false
    }

}
