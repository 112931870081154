import { Injectable } from '@angular/core'
import { HttpHeaders } from '@angular/common/http'
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core'
import { Storage } from '@ionic/storage'
import { HttpLink } from 'apollo-angular/http'
import { persistCache } from 'apollo3-cache-persist'

// eslint-disable-next-line import/extensions
import extractFiles from 'extract-files/extractFiles.mjs'
// eslint-disable-next-line import/extensions
import isExtractableFile from 'extract-files/isExtractableFile.mjs'

import { AppConfig } from '@app-config'
import packageJson from '@project-root/package.json'

export function resolveApolloClientOptions(apolloClientService: ApolloConfigService): any {
    return () => apolloClientService.getOptionsPromise()
}

export function apolloClientServiceFactory(apolloClientService: ApolloConfigService): any {
    return apolloClientService.getOptions()
}

@Injectable({
    providedIn: 'root',
})
export class ApolloConfigService {

    private readonly cache: InMemoryCache

    constructor(
        private readonly httpLink: HttpLink,
        private readonly ionicStorage: Storage,
    ) {
        this.cache = new InMemoryCache({
            typePolicies: {
                Profile: {
                    fields: {
                        profileCommunities: {
                            merge(_, incoming) {
                                return incoming
                            },
                        },
                        follows: {
                            merge(_, incoming) {
                                return incoming
                            },
                        },
                    },
                },
            },
        })
    }

    public getOptions(): ApolloClientOptions<any> {
        const uri = AppConfig.api.graphQLEndpoint

        return {
            link: this.httpLink.create({
                uri,
                headers: new HttpHeaders({
                    'X-Client-Version': packageJson.version,
                }),
                extractFiles: (body) => extractFiles(body, isExtractableFile) as any,
            }),
            cache: this.cache,
        }
    }

    public async getOptionsPromise(): Promise<void> {
        await this.ionicStorage.create()
        await persistCache({
            cache: this.cache,
            key: 'apollo-cache',
            storage: {
                getItem: (key: string) => this.ionicStorage.get(key),
                setItem: (key: string, data: any) => this.ionicStorage.set(key, data),
                removeItem: (key: string) => this.ionicStorage.remove(key),
            },
            serialize: false as any,
        })
    }

}
