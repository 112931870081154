import { Injectable } from '@angular/core'
import { BehaviorSubject, filter, Subject, Subscription } from 'rxjs'
import { Channel } from 'stream-chat'
import {
    ChannelService,
    ChatClientService,
    DefaultStreamChatGenerics,
    StreamMessage,
} from 'stream-chat-angular'

import { User } from '@app-graphql'
import { UserService } from '@app-services/api'
import { AppConfig } from '@app-config'

@Injectable({
    providedIn: 'root',
})
export class ChatService {

    public isConnected = false
    public channel: Channel<DefaultStreamChatGenerics>

    public connected$ = new BehaviorSubject<boolean>(null)
    public channel$ = new Subject<Channel>()
    public messageSent$ = new Subject<StreamMessage>()

    private user$: Subscription
    private user: Partial<User>

    constructor(
        private readonly chatClientService: ChatClientService,
        private readonly channelService: ChannelService,
        private readonly userService: UserService,
    ) {

        this.user$ = this.userService.user$.subscribe((user) => {
            this.user = user
        })

        this.chatClientService.events$
            .pipe(filter((event) => event.eventType === 'message.new'))
            .subscribe(async (event) => {
                const participantId = await this.userService.getChatParticipantId()
                if (event?.event?.user?.id === participantId) {
                    this.messageSent$.next(event.event.message as unknown as StreamMessage)
                }
            })
    }

    public async connect(): Promise<void> {
        if (this.isConnected) {
            return
        }

        const { apiKey } = AppConfig.streamChat

        await this.userService.getUser()

        if (! this.user.profile.chatParticipant?.id) {
            console.error('Chat participant not found')
            return
        }

        await this.chatClientService.init(
            apiKey,
            this.user.profile.chatParticipant.id,
            this.user.profile.chatParticipant.token,
        )

        await this.channelService.init({
            type: 'messaging',
            members: { $in: [this.user.profile.chatParticipant.id] },
        }, {
            last_message_at: -1,
        })

        this.isConnected = true
        this.connected$.next(this.isConnected)
    }

    public async disconnect(): Promise<void> {
        if (! this.isConnected) {
            return
        }

        await this.chatClientService.disconnectUser()

        this.isConnected = false
        this.connected$.next(this.isConnected)
    }

    public async createOrResumeChannel(
        channelId: string,
        settings?: { members: string[], profileIds: string[] },
    ): Promise<Channel> {
        if (settings) {
            this.channel = this.chatClientService.chatClient.channel('messaging', channelId, settings)
        } else {
            this.channel = this.chatClientService.chatClient.channel('messaging', channelId)
        }
        await this.channel.watch()
        this.channel$.next(this.channel)

        this.channelService.setAsActiveChannel(this.channel)

        return this.channel
    }

    public async updateMessage(message: StreamMessage): Promise<void> {
        await this.channelService.updateMessage(message)
    }

}
