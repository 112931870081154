import { Injectable } from '@angular/core'
import {
    ActionSheetController,
    AlertController,
    MenuController,
    ModalController,
    NavController,
    Platform,
    PopoverController,
} from '@ionic/angular'

import { PageHelperService } from '@app-services/ui'

@Injectable()
export class BackButtonService {

    constructor(
        private readonly platform: Platform,
        private readonly navCtrl: NavController,
        private readonly alertCtrl: AlertController,
        private readonly pageHelperService: PageHelperService,
        private readonly popoverCtrl: PopoverController,
        private readonly actionSheetCtrl: ActionSheetController,
        private readonly modalCtrl: ModalController,
        private readonly menuCtrl: MenuController,
    ) {
        //
    }

    public initialize(): void {

        // Bind to the hardware back button on mobile
        if (this.platform.is('hybrid')) {
            this.platform.backButton.subscribeWithPriority(0, this.backButtonHandler.bind(this))

            // In a browser, bind to `window.backButtonTestHandler()` to allow testing of this service
        } else {
            (window as any).backButtonTestHandler = this.backButtonHandler.bind(this)
        }
    }

    public back(): void {
        this.navCtrl.back({
            animated: true,
            animationDirection: 'back',
        })
    }

    protected async backButtonHandler(): Promise<void> {

        // Check if we need to close an alert, popover, action-sheet, modal or side menu
        const hasClosedDialog = await this.closeDialogIfNeeded()
        if (hasClosedDialog) {
            return
        }

        // Get the current page and check if it 'can go back'
        const currentPage = this.pageHelperService.getCurrentPage()
        const canGoBack = currentPage?.classList.contains('can-go-back')

        // The current page is marked as 'can go back' > Navigate back.
        if (canGoBack) {
            this.back()
            return
        }

        // Check if a back button with a default route is found
        const backButton = currentPage?.querySelector('ion-back-button')
        if (backButton) {
            const defaultHref = backButton.getAttribute('defaultHref')
                || backButton.getAttribute('appCustomBackButtonDefaultHref')

            // Navigate to the 'default href' if applicable
            if (defaultHref) {
                await this.navCtrl.navigateBack(defaultHref, {
                    animated: true,
                    animationDirection: 'back',
                })
                return
            }
        }

        // Back to home
        const rootPages = [
            'APP-DUMMY-PAGE',
            'APP-AUTH-INDEX-PAGE',
            'APP-AUTH-RESET-PASSWORD-PAGE',
            'APP-HOME-INDEX-PAGE',
        ]
        if (! rootPages.includes(currentPage!.tagName)) {
            await this.navCtrl.navigateRoot('/', {
                animated: true,
                animationDirection: 'back',
            })
            return
        }

        // Exit the app
        if (this.platform.is('hybrid')) {
            (navigator as any).app.exitApp()
        } else {
            console.log('Exiting app...')
        }
    }

    protected async closeDialogIfNeeded(): Promise<boolean> {

        const visibleAlert = await this.alertCtrl.getTop()
        if (visibleAlert) {
            await visibleAlert.dismiss()
            return true
        }

        const visiblePopover = await this.popoverCtrl.getTop()
        if (visiblePopover) {
            const dismissButton = visiblePopover.querySelector(
                'ion-back-button,[appCustomBackButton],.custom-back-button,.dismiss-button',
            )
            if (dismissButton) {
                dismissButton.dispatchEvent(new CustomEvent('click'))
            } else {
                await visiblePopover.dismiss()
            }
            return true
        }

        const visibleSheet = await this.actionSheetCtrl.getTop()
        if (visibleSheet) {
            await visibleSheet.dismiss()
            return true
        }

        const visibleModal = await this.modalCtrl.getTop()
        if (visibleModal) {
            const dismissButton = visibleModal.querySelector(
                'ion-back-button,[appCustomBackButton],.custom-back-button,.dismiss-button',
            )
            if (dismissButton) {
                dismissButton.dispatchEvent(new CustomEvent('click'))
            } else {
                await visibleModal.dismiss()
            }
            return true
        }

        const menuIsOpen = await this.menuCtrl.isOpen()
        if (menuIsOpen) {
            await this.menuCtrl.close()
            return true
        }

        return false
    }
}
