import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'

import { AuthService, UserService } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {

    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly userService: UserService,
    ) {
        //
    }

    public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

        // Check if logged in
        const isAuthenticated = await this.authService.isAuthenticated()
        if (! isAuthenticated && ! this.authService.authenticationInProgress) {
            await this.router.navigateByUrl('/auth', { replaceUrl: true })
            return false
        }

        // Check if onboarding is completed
        const user = await this.userService.getUser()
        if (! user) {
            await this.router.navigateByUrl('/auth', { replaceUrl: true })
            return false
        }
        if (! user.profile && (route.url.toString() !== 'onboarding')) {
            await this.router.navigateByUrl('/auth/onboarding', { replaceUrl: true })
            return false
        }

        return true
    }

}
