import { NgModule } from '@angular/core'
import { Style as StatusBarStyle } from '@capacitor/status-bar'

import { StatusBarStyleService } from '@app-services'

@NgModule({
    providers: [
        StatusBarStyleService.withConfig({
            ignoredRoutes: [],
            defaultStatusBarStyle: StatusBarStyle.Dark,
        }),
    ],
})
export class NativeServiceModule {
}
