import { Injectable } from '@angular/core'

import { AlertController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'

export interface UnsavedChanges {
    hasUnsavedChanges(): boolean;

    cleanupUnsavedChanges?(): void | Promise<void>;
}

@Injectable({
    providedIn: 'root',
})
export class UnsavedChangesGuard {

    constructor(
        private readonly alertController: AlertController,
        private readonly translateService: TranslateService,
    ) {
        //
    }

    public async canDeactivate(component: UnsavedChanges): Promise<boolean> {
        if (! component.hasUnsavedChanges()) {
            return true
        }

        return new Promise<boolean>((resolve) => {
            this.alertController.create({
                message: this.translateService.instant('validation.confirmUnsavedChanges'),
                buttons: [
                    {
                        text: this.translateService.instant('common.no'),
                        role: 'cancel',
                        handler: () => resolve(false),
                    },
                    {
                        text: this.translateService.instant('common.yes'),
                        role: 'destructive',
                        handler: async () => {
                            if (component.cleanupUnsavedChanges) {
                                await component.cleanupUnsavedChanges()
                            }
                            resolve(true)
                        },
                    },
                ],
            }).then((alert) => alert.present())
        })
    }
}
